@media (min-width: 1536px) {
    .max-w {
        width: 70%;
        max-width: 1600px;
        height: 75vh;
    }
}

@media (min-width: 1280px) {
    .max-w {
        width: 80%;
        max-width: 1600px;
        height: 75vh;
    }
}